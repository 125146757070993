import {
  NativeBaseProvider
} from "native-base";
import { Pocketbase } from "pocketbase-react";
import AuthWrapper from "./components/AuthWrapper";

// FIXME: move to .env
const serverURL = "https://pb.dwallin.com/";
const collections = ["list", "reminders", "sessions", "session_messages", "notes"];
const webRedirectURL = "https://pb.dwallin.com/";

declare global {
  interface window {
    location: any;
  }
}

function App() {
  return (
    <Pocketbase
      serverURL={serverURL}
      initialCollections={collections}
      webRedirectUrl={webRedirectURL}
      mobileRedirectUrl={webRedirectURL}
      openURL={async (url) => {
        // for example expo WebBrowser
        //await WebBrowser.openBrowserAsync(url);
        // window.location = url;
      }}
    >
      <NativeBaseProvider>

          <AuthWrapper />

      </NativeBaseProvider>
    </Pocketbase>
  );
}

export default App;

// App.tsx

//const mobileRedirectURL = "expo://..." // for example
