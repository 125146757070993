import {
	Badge,
	Box,
	Button,
	CheckIcon,
	DeleteIcon,
	Divider,
	FormControl,
	HStack,
	Heading,
	Input,
	KeyboardAvoidingView,
	Pressable,
	ScrollView,
	SearchIcon,
	Spinner,
	TextArea,
	VStack,
	View,
} from "native-base";
import { useAppContent, useAuth } from "pocketbase-react";
import { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { NotesResponse } from "../pocketbase-types";

function SearchForText(text: string, userId: string) {
	return fetch("https://s1.dwallin.com/search", {
		method: "post",
		headers: {
		  'Accept': 'application/json',
		  'Content-Type': 'application/json'
		},
	  
		//make sure to serialize your JSON body
		body: JSON.stringify({
		  ownerId: userId,
		  q: text
		})
	  })
	  .then( (response) => { 
		 const res = response.json();

		 return res;
	  });
}

// Idea: React markdown text editor for notes?
// FIXME: let's do a list of notes, just show title and short desc, much like a google search result, and we'll add a search bar..
export default function NotesPage() {
	const { records, actions } = useAppContent("notes");
	const { user } = useAuth();
	const [notesText, setNotesText] = useState<string>("");
	const [searchText, setSearchText] = useState<string>("");

	const [expandedNotes, setExpandedNotes] = useState<any>({});

	const [searchResults, setSearchResults] = useState<any>([]);

	// FIXME: DRY with removing duplicates..
	let shownNotes = records
		? records
				.reduce((acc: any, current: any) => {
					const x = acc.find((item: any) => item.id === current.id);
					if (!x) {
						return acc.concat([current]);
					} else {
						return acc;
					}
				}, [])
				.sort(
					(a: any, b: any) =>
						new Date(b.created).getTime() -
						new Date(a.created).getTime()
				)
		: [];

	const updateShownNotes = useCallback( () => {
		if(searchResults.length > 0) {
			const top10 = [...searchResults.slice(0, 10)];
			shownNotes = top10.map( (result: any) => {
				return records.find( (note: any) => note.id === result.itemId);
			});
			//shownNotes = shownNotes.filter((note: any) => searchResults.find((result: any) => result.itemId == note.id));
		} else {
			// show only the first 50 notes
			shownNotes = shownNotes.slice(0, 50);
		}
	}, [searchResults, records, shownNotes]);

	updateShownNotes();
		
	useEffect ( () => {
		updateShownNotes();
	}, [searchResults, records, updateShownNotes]);

	const onSendNote = useCallback(() => {
		actions
			.create({
				readLater: false,
				processed: false,
				content: notesText,
				owner: user?.id,
			})
			.then((record: any) => {
				setNotesText("");
			});
	}, [notesText, actions, user]);

	const onToggleExpand = useCallback(
		(id: string) => {
			setExpandedNotes({ ...expandedNotes, [id]: !expandedNotes[id] });
		},
		[expandedNotes]
	);
	
	const onSearchPressed = useCallback(() => {
		if(searchText === "") {
			setSearchResults([]);
			return;
		}
		
		SearchForText(searchText, user?.id || "").then( (response) => {
			const res = [...response.results.sort( (a: any, b: any) => {
				return a.similarity - b.similarity;
			})].filter( (a: any) => (a.similarity) < 0.69);
			console.log(res)
			setSearchResults(res);
		});
		setSearchText("");
	}, [searchText, user]);

    // format date like "January 1, 2021"
    const formatDate = (date: string) => {
        const d = new Date(date);
        return d.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
    }

	const setProcessed = ( note: NotesResponse, processed: boolean ) => {
		actions.update(note.id, { processed: processed });
	}

	const deleteNote = ( note: NotesResponse ) => {
		actions.delete(note.id);
	}

	return (
		<VStack>
			<KeyboardAvoidingView>
				<View borderWidth={1} borderRadius={5} p={2} m={1}>
					<FormControl>
						<HStack width={"100%"}>
							<TextArea
								value={notesText}
								onChangeText={(text: string) =>
									setNotesText(text)
								}
								flex={1}
								mr={3}
								autoCompleteType={true}
								onKeyPress={(value: any) => {
									if (
										value.key === "Enter" &&
										notesText !== ""
									) {
										onSendNote();
									}
								}}
							></TextArea>
							<Button onPress={onSendNote}>Add</Button>
						</HStack>
					</FormControl>
				</View>
			</KeyboardAvoidingView>
			
			<KeyboardAvoidingView px={1}>
			<Divider my={3} />
				<Box>
					<FormControl>
						<Input value={searchText} onChangeText={setSearchText} placeholder="Search" InputRightElement={<Button onPress={onSearchPressed}>
							<SearchIcon color='white' />
						</Button>} />
					</FormControl>
				</Box>
				<Divider my={3} />
			</KeyboardAvoidingView>
			
			<ScrollView>
				<VStack>
					{shownNotes && shownNotes.map((item: NotesResponse) => {

						return item && (
                            <Pressable onPress={() => onToggleExpand(item.id)}>
                                <View
                                    margin={1}
                                    p={2}
                                    borderWidth={1}
                                    borderColor={"coolGray.500"}
                                >
                                    <Heading size="sm" color="coolGray.500">
                                        {item.title || "New note"}{" - "}
                                        {formatDate(item.created)}{" "}
										{item.topic ? <Badge>{item.topic}</Badge> : null }{" "}
                                        {!item.processed && (
                                            <Pressable onPress={() => setProcessed(item, true)}><Spinner color="cyan.500" /></Pressable>
                                        )}
										{expandedNotes[item.id] && item.processed && (
											<Pressable onPress={() => setProcessed(item, false)}><CheckIcon/></Pressable>
										)}
										{expandedNotes[item.id] && (
											<Pressable onPress={() => deleteNote(item)}><DeleteIcon/></Pressable>
										)}										
                                    </Heading>
                                    
                                    <div>{expandedNotes[item.id] && <ReactMarkdown>{item.content}</ReactMarkdown>}</div>
                                </View>
                            </Pressable>
						);
					})}
				</VStack>
			</ScrollView>
		</VStack>
	);
}
