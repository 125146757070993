import { Box, Button, Center, Heading } from "native-base";
import { SessionsPersonaOptions } from "../pocketbase-types";

export interface ExamplePromptsProps {
    sessionType: string;
    setPrompt: (sz: string) => void;
};


export interface ExamplePrompt {
    previewText: string;
    fullPromptText: string;
    type: SessionsPersonaOptions;
}

// Later we can put these in pocketbase
const examplePrompts: ExamplePrompt[] = [
    {
        previewText: "Turn on the lights...",
        fullPromptText: "Turn on the lights",
        type: SessionsPersonaOptions.assistant
    },
    {
        previewText: "In C# how do you..",
        fullPromptText: "In C# how do you",
        type: SessionsPersonaOptions.chat
    },
    {
        previewText: "In Typescript how do you..",
        fullPromptText: "In Typescript how do you",
        type: SessionsPersonaOptions.chat
    },
]

export function ExamplePromptsView(props: ExamplePromptsProps) {
    return <Box my={3} borderRadius={20} borderWidth={1} borderColor={"muted.300"} p={5}>
        <Center>
            <Heading size={"sm"}>Prompts</Heading>
            {examplePrompts.filter((prompt) => prompt.type === props.sessionType).map((item: ExamplePrompt) =>
                <Button mt={2} backgroundColor={"muted.400"} onPress={() => props.setPrompt(item.fullPromptText)}>{item.previewText}</Button>
            )}

        </Center>
    </Box>
}