/**
* This file was @generated using pocketbase-typegen
*/

import type PocketBase from 'pocketbase'
import type { RecordService } from 'pocketbase'

export enum Collections {
	List = "list",
	Notes = "notes",
	Prompts = "prompts",
	Reminders = "reminders",
	SessionMessages = "session_messages",
	Sessions = "sessions",
	Users = "users",
}

// Alias types for improved usability
export type IsoDateString = string
export type RecordIdString = string
export type HTMLString = string

// System fields
export type BaseSystemFields<T = never> = {
	id: RecordIdString
	created: IsoDateString
	updated: IsoDateString
	collectionId: string
	collectionName: Collections
	expand?: T
}

export type AuthSystemFields<T = never> = {
	email: string
	emailVisibility: boolean
	username: string
	verified: boolean
} & BaseSystemFields<T>

// Record types for each collection

export enum ListTypeOptions {
	"todo" = "todo",
	"shopping" = "shopping",
	"wishlist" = "wishlist",
}

export enum ListCategoryOptions {
	"none" = "none",
	"Dairy Aisle" = "Dairy Aisle",
	"Cheese" = "Cheese",
	"Bakery" = "Bakery",
	"Bread" = "Bread",
	"Fruits and Vegetables" = "Fruits and Vegetables",
	"Deli" = "Deli",
	"Organic/Gluten Free" = "Organic/Gluten Free",
	"Canned goods" = "Canned goods",
	"Meat & Poultry" = "Meat & Poultry",
	"International" = "International",
	"Frozen Foods" = "Frozen Foods",
	"Household Goods" = "Household Goods",
	"Other" = "Other",
}
export type ListRecord = {
	category?: ListCategoryOptions
	checked?: boolean
	content: string
	owner: RecordIdString
	type: ListTypeOptions
}

export enum NotesTopicOptions {
	"General" = "General",
	"AI" = "AI",
	"Graphics" = "Graphics",
	"Game Development" = "Game Development",
	"Web Development" = "Web Development",
	"General Programming" = "General Programming",
	"Home Automation" = "Home Automation",
	"Personal Note" = "Personal Note",
	"Recipe" = "Recipe",
}
export type NotesRecord<TsearchInfo = unknown> = {
	content: HTMLString
	link?: string
	owner: RecordIdString
	processed?: boolean
	readLater?: boolean
	searchInfo?: null | TsearchInfo
	shortDesc?: string
	title?: string
	topic?: NotesTopicOptions
}

export type PromptsRecord = {
	body?: HTMLString
	title?: string
}

export enum RemindersWeeklyRecurringDaysOptions {
	"Monday" = "Monday",
	"Tuesday" = "Tuesday",
	"Wednesday" = "Wednesday",
	"Thursday" = "Thursday",
	"Friday" = "Friday",
	"Saturday" = "Saturday",
	"Sunday" = "Sunday",
}
export type RemindersRecord = {
	content: string
	dueDate?: IsoDateString
	isWeeklyRecurring: boolean
	owner: RecordIdString
	weeklyRecurringDays?: RemindersWeeklyRecurringDaysOptions[]
}

export enum SessionMessagesTypeOptions {
	"user" = "user",
	"response" = "response",
}
export type SessionMessagesRecord = {
	message?: string
	session: RecordIdString
	type: SessionMessagesTypeOptions
}

export enum SessionsPersonaOptions {
	"assistant" = "assistant",
	"notes" = "notes",
	"chat" = "chat",
	"coder" = "coder",
}
export type SessionsRecord<Thistory = unknown> = {
	history?: null | Thistory
	label?: string
	owner: RecordIdString
	persona: SessionsPersonaOptions
	pinned?: boolean
}

export type UsersRecord = {
	avatar?: string
	name?: string
}

// Response types include system fields and match responses from the PocketBase API
export type ListResponse<Texpand = unknown> = Required<ListRecord> & BaseSystemFields<Texpand>
export type NotesResponse<TsearchInfo = unknown, Texpand = unknown> = Required<NotesRecord<TsearchInfo>> & BaseSystemFields<Texpand>
export type PromptsResponse<Texpand = unknown> = Required<PromptsRecord> & BaseSystemFields<Texpand>
export type RemindersResponse<Texpand = unknown> = Required<RemindersRecord> & BaseSystemFields<Texpand>
export type SessionMessagesResponse<Texpand = unknown> = Required<SessionMessagesRecord> & BaseSystemFields<Texpand>
export type SessionsResponse<Thistory = unknown, Texpand = unknown> = Required<SessionsRecord<Thistory>> & BaseSystemFields<Texpand>
export type UsersResponse<Texpand = unknown> = Required<UsersRecord> & AuthSystemFields<Texpand>

// Types containing all Records and Responses, useful for creating typing helper functions

export type CollectionRecords = {
	list: ListRecord
	notes: NotesRecord
	prompts: PromptsRecord
	reminders: RemindersRecord
	session_messages: SessionMessagesRecord
	sessions: SessionsRecord
	users: UsersRecord
}

export type CollectionResponses = {
	list: ListResponse
	notes: NotesResponse
	prompts: PromptsResponse
	reminders: RemindersResponse
	session_messages: SessionMessagesResponse
	sessions: SessionsResponse
	users: UsersResponse
}

// Type for usage with type asserted PocketBase instance
// https://github.com/pocketbase/js-sdk#specify-typescript-definitions

export type TypedPocketBase = PocketBase & {
	collection(idOrName: 'list'): RecordService<ListResponse>
	collection(idOrName: 'notes'): RecordService<NotesResponse>
	collection(idOrName: 'prompts'): RecordService<PromptsResponse>
	collection(idOrName: 'reminders'): RecordService<RemindersResponse>
	collection(idOrName: 'session_messages'): RecordService<SessionMessagesResponse>
	collection(idOrName: 'sessions'): RecordService<SessionsResponse>
	collection(idOrName: 'users'): RecordService<UsersResponse>
}
