import { useAppContent, useAuth } from "pocketbase-react";
import { SessionsRecord } from "../pocketbase-types";

import {
	ArrowForwardIcon,
	Box,
	Button,
	Divider,
	HStack,
	Heading,
	Pressable,
	ScrollView,
	Text,
	VStack,
	View,
} from "native-base";
import { useState } from "react";

export interface ChatSessionViewProps {
	onSetSession: (sessionId: string) => void;
}


export interface ChatSessionsHeaderProps {
	onSetSession: (sessionId: string) => void;
}

export function ChatSessionsHeader(props: ChatSessionsHeaderProps) {
	const { actions } = useAppContent("sessions");
	const { user } = useAuth();
	const [sessionType, setSessionType] = useState("assistant");
	const onNewSessionPressed = () => {
		if(!user) {
			console.error("No user");
			return;
		}
		actions
			.create({ persona: sessionType, owner: user?.id })
			.then((record: any) => {
				console.log(record)
				props.onSetSession(record.id);
			});
	};

	return (
		<VStack mt={2} mb={4}>
			<Heading mb={4}>Sessions</Heading>
			<HStack my={2} alignItems={"center"} space={2}>
				<Text>Create Type:</Text>
				<Button
					size="sm"
					variant={sessionType == "assistant" ? "solid" : "outline"}
					onPress={() => setSessionType("assistant")}
				>
					Assistant
				</Button>
				<Button
					size="sm"
					variant={sessionType == "chat" ? "solid" : "outline"}
					onPress={() => setSessionType("chat")}
				>
					Chat
				</Button>
				<Button
					size="sm"
					variant={sessionType == "notes" ? "solid" : "outline"}
					onPress={() => setSessionType("notes")}
				>
					Notes
				</Button>

			</HStack>
			<Box>
				<Button onPress={onNewSessionPressed}>+ New Session</Button>
				<Divider my="4"/>
			</Box>

		</VStack>
	);
}

export default function ChatSessionsView(props: ChatSessionViewProps) {
	const { records } = useAppContent("sessions");
	const listItems = (records || []) as SessionsRecord[];


    const shownList:SessionsRecord[] = listItems.reduce((acc: any, current: any) => {
        const x = acc.find((item: any) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []).sort((a: any, b: any) => new Date(a.created).getTime() - new Date(b.created).getTime());


	// FIXME: need to remove duplicates

	if (!shownList) {
		return (
			<VStack>
				<ChatSessionsHeader onSetSession={props.onSetSession} />
				<Heading size="md" mb="2">History</Heading>
				<Box>
					<Text>No sessions</Text>
				</Box>
			</VStack>
		);
	}

	return (
		<ScrollView>
		
			<VStack px={1}>
				<ChatSessionsHeader onSetSession={props.onSetSession} />
				<Heading size="md" mb="2">History</Heading>
				{shownList.map((item: any) => (
					<Pressable
						mb={3}
						onPress={() => {
							props.onSetSession(item.id);
						}}
					>
						<View
							borderColor={"coolGray.600"}
							borderWidth={1}
							borderRadius={5}
							px={2}
							py={4}
						>
							<HStack mx={2} flex={1}>
								<Text mr={4}>{item.label || item.id}</Text>{" "}
								<View
									flexDir={"row"}
									mb={0}
									flex={1}
									flexGrow={1}
									justifyContent={"flex-end"}
									alignItems={"center"}
								>
									<Box
										mx={4}
										my={0}
										borderRadius={10}
										px={2}
										backgroundColor={"coolGray.400"}
									>
										<Text>{item.persona}</Text>
									</Box>
									<ArrowForwardIcon />
								</View>
							</HStack>
						</View>
					</Pressable>
				))}
			</VStack>
		</ScrollView>
	);
}
