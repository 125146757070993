import { useAppContent } from 'pocketbase-react';
import { useState } from 'react';
import { SessionsPersonaOptions, SessionsRecord } from '../pocketbase-types';
import ChatSessionMessagesView from './ChatSessionMessagesView';
import ChatSessionsView from './ChatSessionsView';


export default function ChatPage() {

	const [currentSessionId, setCurrentSessionId] = useState<string | undefined>(undefined);

	const { records } = useAppContent("sessions");
	const listItems = (records || []) as SessionsRecord[];

	const currentChatSession = listItems.find((item:any) => item.id === currentSessionId);


	if(currentSessionId) {
		return <ChatSessionMessagesView sessionType={currentChatSession?.persona || SessionsPersonaOptions.assistant} sessionId={currentSessionId} sessionLabel={currentChatSession?.label || "Chat Session" } onEndSession={() => {
			// FIXME: delete everything..
			setCurrentSessionId(undefined);
		}} />
	} else {
		return <ChatSessionsView onSetSession={ (id) => {
			setCurrentSessionId(id);
		}} />
	}
}