import { useAppContent, useAuth } from "pocketbase-react";
import { ListResponse } from "../pocketbase-types";

import {
  AddIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  HStack,
  Heading,
  Input,
  KeyboardAvoidingView,
  Pressable,
  Stack,
  Text,
  ThreeDotsIcon,
  VStack,
  View,
} from "native-base";
import { useCallback, useState } from "react";

function toTitleCase(str: string) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

export default function ListView() {
	const { records, actions } = useAppContent("list");
	const { user } = useAuth();
	const listItems = records as ListResponse[];
	const [itemText, setItemText] = useState<string>("");
	const [listType, setListType] = useState<string>("shopping");
	const onAddItem = useCallback(() => {
		if (user && itemText.length > 0) {
			actions.create({
				content: itemText,
				type: listType,
				checked: false,
				owner: user.id,
			});
			setItemText("");
		}
	}, [itemText, listType, user, actions]);
	if (!listItems) {
		return (
			<VStack>
				<Heading pb={5}>{toTitleCase(listType)} List</Heading>
				<Box>Nothing to see here folks...</Box>
			</VStack>
		);
	}

	// remove duplicate listItems that have the same id
	// for some reason pocketbase-react returns duplicate records when you edit something in the admin panel
	const filteredListItems: ListResponse[] = listItems
		.reduce((acc: any, current: any) => {
			const x = acc.find((item: any) => item.id === current.id);
			if (!x && current.type === listType) {
				return acc.concat([current]);
			} else {
				return acc;
			}
		}, [])
		.sort(
			(a: any, b: any) =>
				new Date(b.created).getTime() - new Date(a.created).getTime()
		);

	const checkedItems: ListResponse[] = filteredListItems.filter(
		(item: any) => item.checked
	);
	const uncheckedItems: ListResponse[] = filteredListItems.filter(
		(item: any) => !item.checked
	);

	function groupBy(arr: any, property: string) {
		return arr.reduce(function (memo:any, x:any) {
			if (!x[property]) {
				if (!memo["uncategorized"]) {
					memo["uncategorized"] = [];
				}
				memo["uncategorized"].push(x);
				return memo;
			}
			if (!memo[x[property]]) {
				memo[x[property]] = [];
			}
			memo[x[property]].push(x);
			return memo;
		}, {});
	}

	const groupedItems = groupBy(uncheckedItems, "category");
	const categories = Object.keys(groupedItems);

	const handleChange = (text: string) => setItemText(text);

	return (
		<>
			<HStack my={2} alignItems={"center"} space={2}>
				<Text>List:</Text>
				<Button
					size="sm"
					variant={listType === "todo" ? "solid" : "outline"}
					onPress={() => setListType("todo")}
				>
					Todo
				</Button>
				<Button
					size="sm"
					variant={listType === "shopping" ? "solid" : "outline"}
					onPress={() => setListType("shopping")}
				>
					Shopping
				</Button>
				<Button
					size="sm"
					variant={listType === "wishlist" ? "solid" : "outline"}
					onPress={() => setListType("wishlist")}
				>
					Wishlist
				</Button>
			</HStack>

			<KeyboardAvoidingView>
				<Stack px={1}>
					{/* <Select
				  selectedValue={listType}
				  minWidth="200"
				  onValueChange={(value) => setListType(value)}
          accessibilityLabel="Select list type"
			>
				<Select.Item label="Todo" value="todo" />
				<Select.Item label="Shopping" value="shopping" />
				<Select.Item label="Wishlist" value="wishlist" />
			</Select>           */}

					<FormControl>
						<Input
							placeholder="New item..."
							value={itemText}
							onChangeText={handleChange}
							my={4}
							onKeyPress={({ nativeEvent }) => {
								if (nativeEvent.key === "Enter") {
									onAddItem();
								}
							}}
							InputRightElement={
								<Button onPress={onAddItem}>
									<AddIcon color={"white"} />
								</Button>
							}
						/>
					</FormControl>
				</Stack>
			</KeyboardAvoidingView>

			<VStack px={1}>
				
				{categories.map((category: string) => {
					return (
						<>
              <Divider mt={4} mb={4} />
              <Text mb={2} color={'emerald.600'} key={"category_" + category} fontWeight="bold">{category}</Text>
							{groupedItems[category].map(
								(record: ListResponse) => {
									return (
										<View display={'flex'} flexDirection={'row'} alignItems={'center'}>
											<Checkbox
												size="lg" 
												color={'gray.600'}
												my={1}
												key={"unchecked_" + record.id}
												value={record.id}
												isChecked={record.checked}
												onChange={(state) => {
													actions.update(record.id, {
														...record,
														checked: state,
													});
												}}
											>
												{record.content}

											</Checkbox>
										<Pressable ml={2} onPress={()=>{
												setItemText(record.content);
												actions.delete(record.id);
											}}><ThreeDotsIcon /></Pressable>
									</View>
									);
								}
							)}
						</>
					);
				})}

				<Divider mt={4} mb={2} />
				{checkedItems.length > 0 && (
					<Button
						my={5}
						onPress={() => {
							checkedItems.forEach((record: any) => {
								actions.delete(record.id);
							});
						}}
					>
						Delete checked items
					</Button>
				)}

				{checkedItems.map((record: any) => {
					return (
						<Checkbox
							size="sm"
							key={"checked_" + record.id}
							value={record.id}
							isChecked={record.checked}
							onChange={(state) => {
								actions.update(record.id, {
									...record,
									checked: state,
								});
							}}
						>
							{record.content}

						</Checkbox>
					);
				})}
			</VStack>
		</>
	);
}
