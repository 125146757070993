import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  Heading,
  Input,
  Pressable,
  Text,
  View
} from "native-base";
import { useAuth } from "pocketbase-react";
import { useState } from "react";
import { SceneMap, TabBar, TabView } from "react-native-tab-view";
import ChatPage from "./ChatPage";
import ListView from "./ListView";
import NotesPage from "./NotesPage";

// Authentication wrapper AND main nav

const listScene = () => <ListView />;
const chatScene = () => <ChatPage />
const notesScene = () => <NotesPage />
const renderScene = SceneMap({
  lists: listScene,
  chat: chatScene,
  notes: notesScene,
});

interface NavigationState {
  routes: any[];
  index: number;
}

interface TabBarProps {
  navigationState: NavigationState;
}

export default function AuthWrapper() {
  const { actions, isSignedIn, user } = useAuth();

  console.log(isSignedIn, user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [index, setIndex] = useState(0);
  const [routes] = useState([{
    key: 'lists',
    title: 'Lists'
  }, {
    key: 'chat',
    title: 'Chat'
  }, {
    key: 'notes',
    title: 'Notes'
  }]);

  const renderTabBar = (props:any) => (
    <TabBar
      {...props}
      indicatorStyle={{  backgroundColor: '#22d3ee' }}
      labelStyle={{color: 'black'}}
      style={{ backgroundColor: 'coolGray.200', marginBottom: "10px" }}
    />
  );

  const logoutPressed = () => {
    actions.signOut();
    localStorage.clear();

    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    
    window.location.reload();
  }
  
  return (
    <Center>
      <Container>
        <View width="100%" mt={4}><Center><Heading>Assistant</Heading></Center></View>
        {isSignedIn ? (
          <Box width={'100%'} maxWidth={'500px'}>
            
            {/* <Text>Logged in as {user?.email}</Text> */}
            
            <TabView animationEnabled={false} navigationState={{
    index,
    routes
  }} renderTabBar={renderTabBar} renderScene={renderScene} initialLayout={{width: 375}} onIndexChange={setIndex} style={{marginBottom: "10px"}}/>
           <Pressable style={ {position: "absolute", top: -25, right: 0 }} onPress={logoutPressed}><Text>Logout</Text></Pressable>
          </Box>
        ) : (
          <Box>
          <FormControl isRequired>
            <h1>Login</h1>
            <Input
              type="text"
              id="email"
              placeholder="Email"
              onChangeText={(value: string) => setEmail(value)}
              my={2}
            />
          </FormControl>
          <FormControl isRequired>
            <Input
              type="password"
              id="password"
              placeholder="Password"
              onChangeText={(value: string) => setPassword(value)}
              my={2}
            />
            </FormControl>
            <Button
              mt={2}
              onPress={() => {
                actions.signInWithEmail(email, password).then((result) => {
                  console.log(result);
                });
              }}
            >
              Sign In
            </Button>
          
          </Box>
        )}
      </Container>
    </Center>
  );
}


