import { ArrowBackIcon, Box, Button, Center, CloseIcon, FormControl, HStack, Heading, KeyboardAvoidingView, Pressable, ScrollView, Text, TextArea, VStack, View } from "native-base";
import { useAppContent } from "pocketbase-react";
import { useCallback, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { SessionsPersonaOptions } from "../pocketbase-types";
import { ExamplePromptsView } from "./ExamplePromptsView";

export interface ChatSessionMessagesViewProps {
	sessionId: string;
    sessionLabel: string;
    sessionType: string;
	onEndSession: () => void;
}


export function ChatMessagesHeader(props: ChatSessionMessagesViewProps) {
    const { actions } = useAppContent("session_messages");
    return (


        <HStack mb={3} justifyContent={'space-between'} alignItems={'center'}>
            <Pressable onPress={() => {
                props.onEndSession();
            }}><ArrowBackIcon /></Pressable>
            <Heading>{props.sessionLabel || "Chat Session"}</Heading>
            <Pressable onPress={() => {
                actions.create({ session: props.sessionId, type: 'user', message: 'exit' })
                props.onEndSession();
            }}>
                <CloseIcon /> 
            </Pressable>
        </HStack>
        

    );
}

export default function ChatSessionMessagesView(
	props: ChatSessionMessagesViewProps
) {
	const { records, actions } = useAppContent("session_messages");
    const [chatText, setChatText] = useState<string>('');

    // FIXME: DRY with removing duplicates..      
    const shownRecords = records.filter((item: any) => item.session == props.sessionId).reduce((acc: any, current: any) => {
        const x = acc.find((item: any) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []).sort((a: any, b: any) => new Date(a.created).getTime() - new Date(b.created).getTime());



    const onSendChat = useCallback(() => {
        actions.create({ session: props.sessionId, type: 'user', message: chatText }).then((record: any) => {
            setChatText('');
        });
    }, [chatText]);
    
    // TODO handle markdown and stuff
	return (
        <VStack >
		<ScrollView>
			<VStack px={1} pb={3} >
                <ChatMessagesHeader {...props} />
                {shownRecords.length == 0 && <ExamplePromptsView sessionType={props.sessionType} setPrompt={(sz:string) => setChatText(sz)}  />}
                {shownRecords.map((item: any, index:Number) => {
                    return (item.message != "Processing..." || index == (shownRecords.length-1)) ? (<View maxWidth="800px" alignItems={item.type==='response' ? 'flex-end' : 'flex-start'}>
                        <Text py={1}>{item.type}:</Text>                
                        <View color={'white'} borderRadius={5} px={3} py={0} backgroundColor={ item.type==='response' ? 'emerald.100' : 'blue.100' } flexDir={'row'} key={item.id} alignItems={'flex-start'}>
                            <Text color={'black'}><ReactMarkdown
                                components={{
                                    code({node, inline, className, children, ...props}) {
                                        return <SyntaxHighlighter
                                        {...props}
                                        children={String(children)}
                                        style={dark}
                                        language={'typescript'}
                                        PreTag="div"
                                        wrapLongLines={true}
                                        />
                                    }
                                }}
                            
                            >{item.message}</ReactMarkdown></Text>
                        </View>
                    </View>) : null
                })}


            </VStack>
		</ScrollView>
        <KeyboardAvoidingView>
            <View borderWidth={1} borderRadius={5} p={2} m={1}>
                <FormControl>
                    <HStack width={'100%'}>
                    <TextArea value={chatText} onChangeText={(text:string) => setChatText(text)} flex={1} mr={3} autoCompleteType="on" onKeyPress={(value:any) => {
                        if(value.key === "Enter" && chatText != '') {
                            onSendChat();
                        }
                    }}></TextArea>
                    <Button onPress={onSendChat}>Send</Button>
                    </HStack>
                </FormControl>
            </View>
        </KeyboardAvoidingView>
        </VStack>
	);
}
